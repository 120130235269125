import "./App.css";
import sondaLogo from "./images/sonda.svg";
import LocationMap from "./components/LocationMap";

function App() {
  return (
    <div className="my-20 lg:my-44 font-serif">
      <div className="container mx-auto px-3">
        <div className="max-w-xs">
          <div className="leading-snug">
            Poet Tin Ujević (1891. - 1955.)
            <br />
            during his lifetime:
          </div>
          <div className="leading-7 text-xl flex flex-col gap-4 mt-4">
            <div>
              “While I am alive, you won´t
              <br />
              give me a place to live,
            </div>
            <div>
              but when I die, you will give
              <br />
              me a whole street.“
            </div>
          </div>
        </div>
        <div className="max-w-xs sm:ml-40 lg:ml-60 xl:ml-80 mt-12">
          <div className="mb-4">Poet Tin Ujević today:</div>
          <div className="text-xl leading-7">
            140 streets / squares /<br />
            in Croatia
          </div>
        </div>
      </div>
      <div className="mt-24">
        <LocationMap />
      </div>
      <div className="container mx-auto mt-24 lg:mt-36 px-3">
        <div className="max-w-3xl">
          <h1 className="text-xl mb-3">Augustin Tin Ujević (1891. – 1955.) </h1>
          <p className="leading-snug">
            is considered to be one of the greatest Croatian poets of 20th
            century.  He was born in small town of Vrgorac in Dalmatia, grew up
            in Makarska and Split and spent most of his life in bohemian milieus
            of Zagreb, Belgrade and Sarajevo. As a poet with a unique voice of
            modernist, turn-of-the -century lyricism, but also an essayist,
            translator and feuilletonist, he published a large amount of
            relevant work that made him appreciated and influential both in
            literary circles and in the wider audience during his lifetime.
            Posthumously, he became a true poetic and bohemian icon.
          </p>
        </div>

        <div className="max-w-xs xl:ml-96 mt-28 lg:mt-36 prose-p:my-3 prose-p:leading-snug">
          <h2 className="font-semibold mb-10">A Record on the Doorstep</h2>

          <p>
            These poems - it's not me, even
            <br />
            though I wrote them.
          </p>
          <p>
            These moans, it's not me, though I<br /> really sighed them.
          </p>
          <p>
            My real life, I was just breathing.
            <br />
            Because I also live when the song
            <br />
            dies. I live when the suffering passes.
          </p>
          <p>
            There is a dear unrest in me, and
            <br />
            there is my width. I let another speak
            <br />
            for me.
          </p>
          <p>
            And I speak other ones myself.
            <br />I don't care to be a man if I can speak
            <br />a man in a divine way.
          </p>
          <p>Oh, me.</p>
          <p>
            I am than myself both smaller and
            <br />
            greater.
          </p>
          <p>Oh, me.</p>
          <p>My second and my third.</p>
          <p>
            I don't dream of happiness.
            <br />
            But I have no doubt about happiness.
          </p>
          <p>
            Look at this duality and my trinity:
            <br />
            there is darkness in me,
            <br />
            but there are serenity in me,
            <br />
            and my divine harmony.
          </p>

          <div className="text-xs mt-10 lg:mt-16">
            Translated by Gordana Janjušević Leković, 2019
          </div>
        </div>

        <div className="max-w-xl mt-20 lg:mt-28 leading-snug prose-p:my-3">
          <p>
            Tin Ujević is, arguably, what we would name
            <em>the biggest Croatian poet of all times</em>. Grandmas recall his
            sonnets being read to them as girls, on the way to be disclosed as
            women. Grandsons attend schools named upon him, all those hundreds
            of them. Even the pigeons know him – taking a dump upon his
            well-done, big-scale bronzes.
          </p>
          <p>
            That he was somewhat of a <em>bohemian lifestyle</em> is common
            knowledge. The fact that, as a youth, he was an armed revolutionary
            ready to die for the cause is way less known. But, is a{" "}
            <em>bohemian lifestyle</em>
            always fully a matter of choice? Where did the revolutionary
            politics come from?
          </p>
          <p>
            “While I am alive, you won´t give me a place to live, but when I
            die, you will give me a whole street”, the poet had said. How does
            that add up? Moreover – is <em>the only good poet a dead one</em>?
            The time is, it seems to be, both the revelator and the worst enemy
            of an artist: the living one. Romantic ideas about writing{" "}
            <em>unconditionally</em>
            and <em>for the eternity</em> meet the stereotype of starving
            artists, working in moldy rooms, before they are so kind to die of
            tuberculosis, preferably before they turn thirty, and right before
            polishing their magnum opus.
          </p>
          <p>
            Capitalism is, however, way less romantic. An applause doesn’t seem
            to be paying the rent. And the society taking culture as yet another
            market niche, is on a safe path to end up self-dismantled. Yes, the
            time is the most reliable art-critic, though that’s only partially
            true. Important things get lost in the meantime: details, nuances,
            contexts, and also lives. And the cult of death’s saying: hold on,
            grin and bear it, the heaven is near. Sometimes, when you are no
            more, they’ll be printing your name on envelopes. The pigeon kingdom
            will come.
          </p>
          <div className="mt-8 text-sm">Marko Pogačar, writer</div>
        </div>
        <div className="mt-28 sm:mt-48 lg:mt-96 text-xs leading-snug">
          ED Awards Naples 2024 <br />– ANIME exhibition
        </div>

        <div className="mt-10 lg:mt-20 flex gap-6 items-end">
          <img src={sondaLogo} alt="Sonda logo" className="w-16 h-auto" />
          <div className="text-xs">2024. Studio Sonda</div>
        </div>
      </div>
    </div>
  );
}

export default App;
