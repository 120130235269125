import {
  APIProvider,
  InfoWindow,
  Map,
  Marker,
} from "@vis.gl/react-google-maps";
import marker from "../images/marker-dot.svg";
import xIcon from "../images/x-icon.svg";
import { useState } from "react";

interface Location {
  name: string;
  lat: number;
  lng: number;
}

export default function LocationMap() {
  const [selectedMarker, setSelectedMarker] = useState<Location | null>(null);

  return (
    <APIProvider apiKey="AIzaSyDNvNm32hDYF_Y0cp3I9vS7bPKq5EOaGU0">
      <div className="w-full h-96 sm:h-[600px] lg:h-[838px]">
        <Map
          style={{ width: "100%", height: "100%" }}
          defaultCenter={{ lat: 45.039334103086006, lng: 15.368778895430212 }}
          defaultZoom={8}
          disableDefaultUI={true}
          zoomControl={true}
          styles={mapStyle}
          scrollwheel={false}
          restriction={{
            latLngBounds: {
              north: 46.5547,
              south: 42.393,
              west: 13,
              east: 19.5,
            },
            strictBounds: false,
          }}
          defaultBounds={{
            east: 19.3905,
            north: 46.5547,
            south: 42.393,
            west: 13.656,
          }}
        >
          {locations.map((location, index) => (
            <Marker
              key={index}
              position={{ lat: location.lat, lng: location.lng }}
              icon={marker}
              title={location.name}
              onClick={() => setSelectedMarker(location)}
            />
          ))}
          {selectedMarker !== null && (
            <InfoWindow
              headerDisabled
              position={{
                lat: selectedMarker.lat,
                lng: selectedMarker.lng,
              }}
            >
              <div className="pr-1 pt-1">
                {selectedMarker.name}
                <img
                  src={xIcon}
                  alt="Close"
                  className="w-2 h-2 absolute top-2 right-2"
                  onClick={() => setSelectedMarker(null)}
                />
              </div>
            </InfoWindow>
          )}
        </Map>
      </div>
    </APIProvider>
  );
}

const locations: Location[] = [
  { name: "BRCKOVLJANI", lat: 45.828239818842285, lng: 16.291848068884775 },
  { name: "DUGO SELO", lat: 45.81199269994423, lng: 16.238078535821792 },
  { name: "JASTREBARSKO", lat: 45.67429221724596, lng: 15.656674045602847 },
  { name: "KLINČA SELA", lat: 45.700869050447245, lng: 15.74127971394001 },
  { name: "SAMOBOR", lat: 45.83595877786393, lng: 15.691533820724718 },
  { name: "SAMOBOR", lat: 45.79307760576084, lng: 15.716378386953926 },
  { name: "SVETA NEDELJA", lat: 45.81049483166387, lng: 15.792422992934684 },
  { name: "SVETA NEDELJA", lat: 45.75999105620335, lng: 15.809474255207187 },
  { name: "VELIKA GORICA", lat: 45.715991240488, lng: 16.082948022338226 },
  { name: "VELIKA GORICA", lat: 45.73880451025031, lng: 16.01866028432916 },
  { name: "ZAPREŠIĆ", lat: 45.871332412851636, lng: 15.796363814447785 },
  { name: "ZABOK", lat: 46.02821844542632, lng: 15.900930068537228 },
  { name: "DVOR", lat: 45.064491816819, lng: 16.372009616445627 },
  { name: "NOVSKA", lat: 45.33700754643263, lng: 16.970423926177066 },
  { name: "PETRINJA", lat: 45.43987178646883, lng: 16.360831351306963 },
  { name: "PETRINJA", lat: 45.44594979244778, lng: 16.283973669610724 },
  { name: "SISAK", lat: 45.46745568117668, lng: 16.378496283854144 },
  { name: "DUGA RESA", lat: 45.453714919689446, lng: 15.491338155017925 },
  { name: "KARLOVAC", lat: 45.489881718301035, lng: 15.544984955019544 },
  { name: "OGULIN", lat: 45.25911121208509, lng: 15.229201368502531 },
  { name: "GORNJI KNEGINEC", lat: 46.24784665960422, lng: 16.384589383889516 },
  { name: "LUDBREG", lat: 46.24706980080073, lng: 16.6261919415607 },
  { name: "NOVI MAROF", lat: 46.164334520774474, lng: 16.328071992965796 },
  { name: "NOVI MAROF", lat: 46.16418817300671, lng: 16.32717757135022 },
  { name: "NOVI MAROF", lat: 46.16388660089286, lng: 16.328199272112712 },
  { name: "SVETI ILIJA", lat: 46.24236439841611, lng: 16.31627584011171 },
  { name: "VARAŽDIN", lat: 46.28440350417222, lng: 16.30151888204221 },
  { name: "VARAŽDIN", lat: 46.29892982135726, lng: 16.3346041532072 },
  { name: "ĐURĐEVAC", lat: 46.03708362035246, lng: 17.061611183879986 },
  { name: "KOPRIVNICA", lat: 46.17059331788492, lng: 16.805518295530195 },
  { name: "KRIŽEVCI", lat: 46.0250015071002, lng: 16.54322826853704 },
  { name: "BJELOVAR", lat: 45.906237243142414, lng: 16.81655543969611 },
  { name: "DARUVAR", lat: 45.59463354623059, lng: 17.2127004685176 },
  { name: "GRUBIŠNO POLJE", lat: 45.70115951085772, lng: 17.174769753180044 },
  { name: "CRIKVENICA", lat: 45.18529430832038, lng: 14.682301155005863 },
  { name: "DELNICE", lat: 45.39380038751302, lng: 14.801813283850871 },
  { name: "KRK", lat: 45.024051952828295, lng: 14.566081754998583 },
  {
    name: "MALINSKA-DUBAŠNICA",
    lat: 45.12123661917636,
    lng: 14.533027726167438,
  },
  { name: "OMIŠALJ", lat: 45.16258296864569, lng: 14.545212639662502 },
  { name: "RIJEKA", lat: 45.3530878805703, lng: 14.371185110835473 },
  { name: "GOSPIĆ", lat: 44.551329574550756, lng: 15.365187010799598 },
  { name: "NOVALJA", lat: 44.55108840356441, lng: 14.886650854977596 },
  { name: "OTOČAC", lat: 44.87375503317259, lng: 15.236216343672272 },
  { name: "ORAHOVICA", lat: 45.525906873366694, lng: 17.87749919216181 },
  { name: "PITOMAČA", lat: 45.96766617379332, lng: 17.235016355041274 },
  { name: "SLATINA", lat: 45.69531191655194, lng: 17.69242960900176 },
  { name: "VIROVITICA", lat: 45.81350803028927, lng: 17.439311855034155 },
  { name: "VIROVITICA", lat: 45.8315940105704, lng: 17.39255723969272 },
  { name: "LIPIK", lat: 45.416466596109856, lng: 17.163734951555814 },
  { name: "PAKRAC", lat: 45.44846238809471, lng: 17.19139652618215 },
  { name: "POŽEGA", lat: 45.333167230543, lng: 17.66679666850571 },
  { name: "VELIKA", lat: 45.46426797282906, lng: 17.658396144604627 },
  { name: "KLAKAR", lat: 45.14755521130177, lng: 18.08441583966193 },
  { name: "NOVA GRADIŠKA", lat: 45.260757866955956, lng: 17.372678781995745 },
  { name: "SIBINJ", lat: 45.18769342589836, lng: 17.918297546279092 },
  { name: "SLAVONSKI BROD", lat: 45.17788032801582, lng: 18.002856412676653 },
  { name: "BENKOVAC", lat: 44.03230799465234, lng: 15.610144739612295 },
  { name: "BIOGRAD NA MORU", lat: 43.9355446227317, lng: 15.444284953101278 },
  { name: "GRAČAC", lat: 44.30543051249101, lng: 15.857566791566052 },
  { name: "JASENICE", lat: 44.216529797207805, lng: 15.554214226127177 },
  { name: "OBROVAC", lat: 44.14739144078025, lng: 15.633802612630893 },
  { name: "PAG", lat: 44.44891064750139, lng: 15.03522158195944 },
  { name: "PAKOŠTANE", lat: 43.911104085078065, lng: 15.513025995429036 },
  { name: "POLIČNIK", lat: 44.14478101540009, lng: 15.31478458535334 },
  { name: "POLIČNIK", lat: 44.19003105070419, lng: 15.400172314481898 },
  { name: "POLIČNIK", lat: 44.19001886239061, lng: 15.4000799670354 },
  { name: "STARIGRAD", lat: 44.28841597288894, lng: 15.454356681952415 },
  { name: "ZADAR", lat: 44.13231888102661, lng: 15.216574654959002 },
  { name: "POVLJANA", lat: 44.34178544456648, lng: 15.117717381954721 },
  { name: "PRIVLAKA", lat: 44.25670936073913, lng: 15.123674512635702 },
  { name: "VRSI", lat: 44.25883437044899, lng: 15.219767370275875 },
  { name: "ANTUNOVAC", lat: 45.49700545941221, lng: 18.675201455019888 },
  { name: "BELI MANASTIR", lat: 45.78162532718044, lng: 18.596300839690414 },
  { name: "BELIŠĆE", lat: 45.68149977144691, lng: 18.411036455028288 },
  { name: "BILJE", lat: 45.60885768973513, lng: 18.754921070367335 },
  { name: "ČEMINAC", lat: 45.67556901438875, lng: 18.63570671269913 },
  { name: "ČEPIN", lat: 45.53051618374949, lng: 18.560144570363835 },
  { name: "DARDA", lat: 45.63635470358638, lng: 18.69797801084824 },
  { name: "ĐAKOVO", lat: 45.31152185298283, lng: 18.4013873973403 },
  { name: "ERDUT", lat: 45.49783674806413, lng: 18.99710361655551 },
  {
    name: "KNEŽEVI VINOGRADI",
    lat: 45.750805171371084,
    lng: 18.686225068524596,
  },
  { name: "NAŠICE", lat: 45.484393921879786, lng: 18.08955622433466 },
  { name: "OSIJEK", lat: 45.55662533102987, lng: 18.665466997351484 },
  { name: "OSIJEK", lat: 45.53158283280642, lng: 18.834889224336727 },
  { name: "OSIJEK", lat: 45.51796046646488, lng: 18.731434421506044 },
  { name: "OSIJEK", lat: 45.53158283280642, lng: 18.834889224336727 },
  { name: "VALPOVO", lat: 45.661564641099886, lng: 18.40631022619175 },
  { name: "DRNIŠ", lat: 43.8591396627319, lng: 16.16280928193358 },
  { name: "KNIN", lat: 44.03996670822905, lng: 16.192981839612646 },
  { name: "PRIMOŠTEN", lat: 43.591134367252565, lng: 15.926177497264186 },
  { name: "ŠIBENIK", lat: 43.73421158428615, lng: 15.901088626106006 },
  { name: "VODICE", lat: 43.75889669760428, lng: 15.787386626107047 },
  { name: "PIROVAC", lat: 43.82310114691895, lng: 15.66064516843867 },
  { name: "ANDRIJAŠEVCI", lat: 45.22604645175398, lng: 18.747747837816235 },
  { name: "GUNJA", lat: 44.88292658661174, lng: 18.84490508311054 },
  { name: "ILOK", lat: 45.22451423076499, lng: 19.397399626172124 },
  { name: "ILOK", lat: 45.23141451849879, lng: 19.276839055008 },
  { name: "IVANKOVO", lat: 45.290666087894216, lng: 18.703477800650212 },
  { name: "LOVAS", lat: 45.23187395360012, lng: 19.16917144417795 },
  { name: "NUŠTAR", lat: 45.31011189524438, lng: 18.85126835501145 },
  { name: "VINKOVCI", lat: 45.301358053217754, lng: 18.819257568504337 },
  { name: "VUKOVAR", lat: 45.33978417335347, lng: 19.011959568505983 },
  { name: "OTOK", lat: 45.145198218741974, lng: 18.903673090689463 },
  { name: "BAŠKA VODA", lat: 43.35638638845739, lng: 16.95341996841832 },
  { name: "BOL", lat: 43.26239570887344, lng: 16.665528412592206 },
  { name: "GRADAC", lat: 43.10696666337168, lng: 17.34579351073633 },
  { name: "GRADAC", lat: 43.123971639411685, lng: 17.301130426079435 },
  { name: "IMOTSKI", lat: 43.44826073274329, lng: 17.215030635408002 },
  { name: "KAŠTELA", lat: 43.559306066667375, lng: 16.31983971075598 },
  { name: "KOMIŽA", lat: 43.04194807798253, lng: 16.09054876840476 },
  { name: "MAKARSKA", lat: 43.29530763761971, lng: 17.01962362670941 },
  { name: "MILNA", lat: 43.34660048427107, lng: 16.482958533418177 },
  { name: "PODBABLJE", lat: 43.434027771497774, lng: 17.16106233958618 },
  { name: "PODBABLJE", lat: 43.41425018451734, lng: 17.194465928873193 },
  { name: "PODBABLJE", lat: 43.43463701655238, lng: 17.11410520853166 },
  { name: "PODGORA", lat: 43.23753801082281, lng: 17.07773612423519 },
  { name: "SPLIT", lat: 43.51136832837825, lng: 16.436166683767386 },
  { name: "STARI GRAD", lat: 44.28840829282378, lng: 15.454442512637128 },
  { name: "SUPETAR", lat: 43.38298261600697, lng: 16.54863797026861 },
  { name: "TRILJ", lat: 43.62111194069925, lng: 16.717367810758617 },
  { name: "TROGIR", lat: 43.51427338586501, lng: 16.25638802794548 },
  { name: "VRGORAC", lat: 43.20460989949741, lng: 17.367579483754085 },
  { name: "ZMIJAVCI", lat: 43.418356394763336, lng: 17.211631981914216 },
  { name: "DUGOPOLJE", lat: 43.60501357308968, lng: 16.548718739593557 },
  { name: "POREČ - PARENZO", lat: 45.22988644575954, lng: 13.608127281994333 },
  { name: "PULA - POLA", lat: 44.86444357588199, lng: 13.845709068484792 },
  { name: "ROVINJ - ROVIGNO", lat: 45.07549939394644, lng: 13.652526053151853 },
  { name: "DUBROVNIK", lat: 42.655049752438075, lng: 18.090036370237208 },
  { name: "KORČULA", lat: 42.95267654991413, lng: 17.13875819723641 },
  { name: "METKOVIĆ", lat: 43.051614830803054, lng: 17.648976039569543 },
  { name: "OPUZEN", lat: 43.01914576549127, lng: 17.559743710732597 },
  { name: "OREBIĆ", lat: 42.9756991138463, lng: 17.184878497237428 },
  { name: "PLOČE", lat: 43.04786115896279, lng: 17.527117341418446 },
  { name: "PLOČE", lat: 43.05767141222064, lng: 17.429231027925702 },
  { name: "ČAKOVEC", lat: 46.382161000877765, lng: 16.422923497388954 },
  { name: "ČAKOVEC", lat: 46.3652787557961, lng: 16.468655539717023 },
  { name: "PRELOG", lat: 46.3507976028911, lng: 16.66935123971631 },
  { name: "PRELOG", lat: 46.32804824250077, lng: 16.613558039715343 },
  { name: "GRAD ZAGREB", lat: 45.83816318806502, lng: 16.11154083969296 },
  { name: "GRAD ZAGREB", lat: 45.79146022761624, lng: 15.949328555033178 },
];

const mapStyle = [
  {
    featureType: "all",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
      {
        hue: "#ff0000",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#944242",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#eeeeee",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape.natural.landcover",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.natural.terrain",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
      {
        saturation: "-1",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.attraction",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#d9d9d9",
      },
      {
        visibility: "on",
      },
      {
        weight: "0.5",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
      {
        color: "#494949",
      },
      {
        saturation: "-85",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#888888",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#7f7f7f",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.airport",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.bus",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.rail",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#dddddd",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];
